import React from 'react'
import { Helmet } from 'react-helmet'

// import Seo from './Seo' // @todo add this back!

const Head = props => {

    const htmlTitle = props.htmlTitle ? `Yolk Data | ${props.htmlTitle}` : `Yolk Data`

    return (
        <Helmet>
            <title>{htmlTitle}</title>
            <meta charSet="utf-8" />
            <meta name="robots" content="noindex"></meta>
        </Helmet>
    )
}

export { Head }